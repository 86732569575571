dmx.Component('input-file-multiple', {

  extends: 'form-element',

  initialData: {
    files: [],
  },

  _setValue (value) {
    console.warn('Can not set value of a file input!');
  },

  _changeHandler (event) {
    dmx.Component('form-element').prototype._changeHandler.call(this, event);

    let files = [];

    if (this.$node.files.length) {
      files = Array.from(this.$node.files).map((file, index) => {
        const data = {
          date: (file.lastModified ? new Date(file.lastModified) : file.lastModifiedDate).toISOString(),
          name: file.name,
          size: file.size,
          type: file.type,
        };

        if (file.type.includes('image/')) {
          const fileReader = new FileReader();
          fileReader.onload = event => {
            files = dmx.clone(files);
            files[index].dataUrl = event.target.result;
            this.set('files', files);
          };
          fileReader.readAsDataURL(file);
        }

        return data;
      });
    }

    this.set('files', files);
  },

});
