dmx.Component('button', {

  extends: 'form-element',

  attributes: {
    type: {
      type: String,
      default: 'button',
      enum: ['button', 'reset', 'submit'],
    },
  },

  init (node) {
    node.type = this.props.type;
  },

});
