dmx.BaseComponent = dmx.createClass({

    constructor: function(node, parent) {
        this.$node = node;
        this.parent = parent;
        this.bindings = {};
        this.propBindings = {};
        this.children = [];
        this.listeners = {};
        
        this._prevProps = {};
        this._updatedProps = new Set();
        
        this.updatedProps = new Map();
        this.updateRequested = false;

        this.isInitialized = false;
        this.isDestroyed = false;

        this.props = new Proxy({}, {
            set: (target, prop, value, receiver) => {
                const oldValue = Reflect.get(target, prop, receiver);
                const ok = Reflect.set(target, prop, value, receiver);
                
                if (ok && this.isInitialized && !dmx.equal(oldValue, value)) {
                    this._updatedProps.add(prop);
                    this.requestUpdate(prop, oldValue);
                    dmx.requestUpdate(prop);
                }

                return ok;
            }
        });

        this.data = dmx.signalProxy();
        this.seed = Math.random();

        this.name = node.getAttribute('id') || node.getAttribute('name') || (this.type && this.type.toLowerCase().replace(/^dmx-/, '')) || '';
        this.name = this.name.replace(/[^\w]/g, '');
        this.dmxDomId = node.getAttribute('dmxDomId');

        try {
            this.$initialData();
            this.$parseAttributes(node);
            this.init(node);
            if (this.render !== false) {
                this.render(node);
            }
            if (this.$node) {
                this.$customAttributes('mounted', this.$node);
                if (this.dmxDomId) {
                    // Restore dmxDomId for Wappler
                    this.$node.setAttribute('dmxDomId', this.dmxDomId);
                }
                this.$node.dmxComponent = this;
                this.$node.dmxRendered = true;
            }
            this.isInitialized = true;
        } catch (e) {
            console.error(e);
        }
    },

    tag: null,
    initialData: {},
    attributes: {},
    methods: {},
    events: {
        destroy: Event
    },

    render: function(node) {
        if (this.$node) {
            this.$parse();
        }
    },

    parse: function(expression) {
        return dmx.parse(expression, this);
    },

    // find component based on name inside children
    find: function(name) {
        if (this.name == name) return this;

        for (var i = 0; i < this.children.length; i++) {
            var found = this.children[i].find(name);
            if (found) return found;
        }

        return null;
    },

    // internal method for Wappler
    __find: function(dmxDomId) {
        if (this.dmxDomId == dmxDomId) return this;

        for (var i = 0; i < this.children.length; i++) {
            var found = this.children[i].__find(dmxDomId);
            if (found) return found;
        }

        return null;
    },

    // internal method for Wappler
    __replace: function(dmxDomId) {
        var child = this.__find(dmxDomId);

        if (child) {
            child.$destroy();

            var node = document.querySelector('[dmxDomId="' + dmxDomId + '"]');
            if (node) {
                var index = child.parent.children.indexOf(child);
                var Component = dmx.__components[child.data.$type];

                if (index > -1 && Component) {
                    var component = new Component(node, child.parent);
                    child.parent.children.splice(index, 1, component);
                    if (component.name) {
                        child.parent.add(component.name, component.data);
                    }
                }
            }

            dmx.requestUpdate();
        }
    },

    // internal method for Wappler
    __remove: function(dmxDomId) {
        var child = this.__find(dmxDomId);

        if (child) {
            child.$destroy();

            var index = child.parent.children.indexOf(this);
            if (index > -1) {
                child.parent.children.splice(index, 1);
            }

            dmx.requestUpdate();
        }
    },

    init: dmx.noop,

    beforeUpdate: dmx.noop,
    update: dmx.noop,
    updated: dmx.noop,

    beforeDestroy: dmx.noop,
    destroy: dmx.noop,
    destroyed: dmx.noop,

    addEventListener: function(type, callback) {
        if (!(type in this.listeners)) {
            this.listeners[type] = new Set();
        }
        this.listeners[type].add(callback);
    },

    removeEventListener: function(type, callback) {
        if (!(type in this.listeners)) return;
        this.listeners[type].delete(callback);
    },

    dispatchEvent: function(event, props, data, nsp) {
        if (this.isDestroyed) return;

        if (typeof event == 'string') {
            var ComponentEvent = this.events[event] || CustomEvent;
            event = new ComponentEvent(event, props);
        }

        if (!(event.type in this.listeners)) return true;

        event.nsp = nsp;
        event.target = this;
        event.$data = data || {};
        for (let listener of this.listeners[event.type]) {
            if (listener.call(this, event) === false) {
                event.preventDefault();
            }
        }

        return !event.defaultPrevented;
    },

    $createChild: function(name, node) {
        var Component = dmx.__components[name];
        var component = new Component(node, this);
        this.$addChild(component, component.name);
    },

    $addChild: function(child, name) {
        this.children.push(child);
        if (name) {
            if (this.data[name] && dmx.debug) {
                console.warn('Duplicate name "' + name + '" found, component not added to scope.');
                //return;
            }
            this.set(name, child.data);
        }
    },

    $removeChild: function(child) {
        // remove from children collection
        if (this.children.includes(child)) {
            this.children.splice(this.children.indexOf(child), 1);
        }
        // remove from data
        if (child.name && this.data[child.name]) {
            this.del(child.name);
        }
    },

    $customAttributes: function(hook, node) {
        const toCamelCase = (s) => s.replace(/-./g, x => x[1].toUpperCase());

        dmx.dom.getAttributes(node).forEach(attr => {
            if (attr.name == 'bind' && this.attributes[toCamelCase(attr.argument)]) {
                return;
            }

            if (attr.name == 'on' && this.events[attr.argument]) {
                return;
            }

            if (dmx.__attributes[hook][attr.name]) {
                dmx.__attributes[hook][attr.name].call(this, node, attr);
            }
        });
    },

    $parseTextNode(node) {
        if (node.nodeType !== 3) return;

        if (dmx.reExpression.test(node.nodeValue)) {
            const parts = node.nodeValue.replace(dmx.reExpressionReplace, (_, expression) => {
                return `##split##${expression}##split##`;
            }).split('##split##');

            const fragment = document.createDocumentFragment();
            parts.forEach((part, i) => {
                const textNode = document.createTextNode(part);
                fragment.appendChild(textNode);

                
                if (i % 2) {
                    this.$watch(part, value => {
                        textNode.nodeValue = value
                    });
                }
            });

            node.parentNode.replaceChild(fragment, node);
        }
    },

    $parse: function(node) {
        node = node || this.$node;

        if (!node) return;

        if (node.nodeType === 3) {
            return this.$parseTextNode(node);
        }

        if (node.nodeType !== 1) return;

        if (dmx.config.mapping) {
            Object.keys(dmx.config.mapping).forEach(map => {
                dmx.array(node.querySelectorAll(map)).forEach(node => {
                    if (!node.hasAttribute('is')) {
                        node.setAttribute('is', 'dmx-' + dmx.config.mapping[map]);
                    }
                });
            });
        }

        dmx.dom.walk(node, function(node) {
            if (node == this.$node) {
                // skip current node
                return;
            }

            // Element Node
            if (node.nodeType === 1) {
                var tagName = node.tagName.toLowerCase();
                var attributes = dmx.dom.getAttributes(node);

                if (node.hasAttribute('is')) {
                    tagName = node.getAttribute('is');
                }

                if (dmx.reIgnoreElement.test(tagName)) {
                    // ignore element
                    return false;
                }

                this.$customAttributes('before', node);
                var idx = attributes.findIndex(attr => attr.name === 'repeat');
                if (idx !== -1) return false;

                if (dmx.rePrefixed.test(tagName)) {
                    tagName = tagName.replace(/^dmx-/i, '');

                    if (tagName in dmx.__components) {
                        node.isComponent = true;
                        if (!node.dmxRendered) {
                          this.$createChild(tagName, node);
                        } else if (window.__WAPPLER__) {
                            // This breaks some components in design view
                            // causes flows to trigger constantly
                            // components ofter have there own parsing and this breaks it
                            if (node.dmxComponent && node.dmxComponent.$parse) {
                                // for now ignode specific for flows with script tag
                                if (!dmx.reIgnoreElement.test(node.tagName)) {
                                    node.dmxComponent.$parse();
                                }
                            }
                        }
                        return false;
                    } else {
                        console.warn('Unknown component found! ' + tagName);
                        return;
                    }
                }

                this.$customAttributes('mounted', node);
            }

            // Text Node
            if (node.nodeType === 3) {
                this.$parseTextNode(node);
            }
        }, this);
    },

    $update: function(idents) {
        if (this.beforeUpdate(idents) !== false) {
            this.update(this._prevProps, this._updatedProps);
            this.children.forEach(child => { child.$update(idents) });
            this.updated();
            this._prevProps = dmx.clone(this.props);
            this._updatedProps.clear();
        }
    },

    $parseAttributes: function(node) {
        const toKebabCase = (s) => s.replace(/[A-Z]/g, (c) => '-' + c.toLowerCase());;

        for (const name in this.attributes) {
            const opts = this.attributes[name];
            const attrName = toKebabCase(name);

            let value = opts.default;

            // static
            if (node.hasAttribute(attrName)) {
                if (opts.type === Boolean) {
                    value = node.getAttribute(attrName) !== 'false';
                } else {
                    value = node.getAttribute(attrName);

                    if (opts.type === Number) {
                        // Only set number is a valid number is given
                        if (value && isFinite(Number(value))) {
                            value = Number(value);
                        }
                    }

                    if (opts.type === Object || opts.type === Array) {
                        try {
                            value = JSON.parse(value);
                        } catch (err) {
                            console.warn('Invalid attribute value, expected a JSON string got ' + value);
                        }
                    }

                    if (opts.enum && !opts.enum.includes(value)) {
                        value = opts.default;
                    }

                    if (opts.validate && !opts.validate(value)) {
                        value = opts.default;
                    }
                }

                opts.default = value;
            }

            // dynamic
            if (node.hasAttribute('dmx-bind:' + attrName)) {
                const expression = node.getAttribute('dmx-bind:' + attrName);

                this.$watch(expression, (value) => {
                    if (value === undefined) {
                        value = opts.default;
                    } else if (opts.type === Boolean) {
                        value = !!value;
                    } else {
                        if (value != null) {
                            if (opts.type === Number) {
                                if (typeof value === 'string') {
                                    if (value && isFinite(Number(value))) {
                                        value = Number(value);
                                    } else {
                                        value = opts.default;
                                    }
                                } else if (typeof value !== 'number' || !isFinite(Number(value))) {
                                    value = opts.default;
                                }
                            }

                            if (opts.type === String) {
                                value = String(value);
                            }

                            if (opts.type === Object && typeof value !== 'object') {
                                value = opts.default;
                            }

                            if (opts.type === Array) {
                                value = Array.from(value);
                            }
                        }

                        if (opts.enum && !opts.enum.includes(value)) {
                            value = opts.default;
                        }

                        if (opts.validate && !opts.validate(value)) {
                            value = opts.default;
                        }
                    }

                    this.props[name] = value;
                    // TODO: for old components (remove)
                    this.props[attrName] = value;
                });
            } else {
                this.props[name] = value;
                // TODO: for old components (remove)
                this.props[attrName] = value;
            }
        }

        for (const event in this.events) {
            if (node.hasAttribute('on' + event)) {
                dmx.eventListener(this, event, Function('event', node.getAttribute('on' + event)), {});
            }
        }

        dmx.dom.getAttributes(node).forEach(attr => {
            if (attr.name == 'on' && this.events[attr.argument]) {
                dmx.eventListener(this, attr.argument, event => {
                    if (event.originalEvent) {
                        event = event.originalEvent;
                    }

                    var returnValue = dmx.parse(attr.value, dmx.DataScope({
                        $event: event.$data,
                        $originalEvent: event
                    }, this));

                    return returnValue;
                }, attr.modifiers);
            }
        });
    },

    requestUpdate: function(prop, oldValue) {
        //console.log(`request Update ${this.name} (${prop}: ${oldValue} => ${this.prop})`);
        if (!this.performUpdate) return;

        if (!this.updatedProps.has(prop)) {
            this.updatedProps.set(prop, oldValue);
        }

        if (!this.updateRequested) {
            //console.log('queue Microtask', this.name, this.updateRequested);
            //queueMicrotask(() => {
            dmx.nextTick(() => {
                //console.log('exec Microtask', this.name, this.updateRequested);
                if (this.isDestroyed) return;
                this.updateRequested = false;
                this.performUpdate(this.updatedProps);
                this.updatedProps.clear();
            });
        }

        this.updateRequested = true;
    },

    $initialData: function() {
        Object.assign(
            this.data,
            { $type: this.type },
            typeof this.initialData == 'function' ? this.initialData() : this.initialData
        );

        Object.keys(this.methods).forEach(function(method) {
            var self = this;
            this.data['__' + method] = function() {
                return self.methods[method].apply(self, Array.prototype.slice.call(arguments, 1));
            };
        }, this);
    },

    // alias for $watch
    $addBinding: function(expression, cb) {
        this.$watch(expression, cb);
    },

    $watch: function(expression, cb) {
        if (!this.effects) this.effects = [];
        let init = true;
        this.effects.push(dmx.effect(() => {
            if (init) {
                cb.call(this, this.parse(expression));
                init = false;
            } else {
                const value = this.parse(expression);
                queueMicrotask(() => cb.call(this, value));
            }
        }));
    },

    $destroy: function() {
        this.dispatchEvent('destroy');
        this.beforeDestroy();
        this.destroy();
        this.isDestroyed = true;
        if (this.parent) {
            this.parent.$removeChild(this);
        }
        if (this.$node) {
            dmx.dom.remove(this.$node);
        }
        if (this.effects) {
            this.effects.forEach(effect => {
                effect();
            });
            this.effects = null;
        }
        this.$destroyChildren();
        this.$node = null;
        this.parent = null;
        this.data = {};
        this.destroyed();
    },

    $destroyChildren: function() {
        this.children.forEach(child => { child.$destroy() });
        this.children = [];
    },

    get: function(name, ignoreParents) {
        if (this.data[name] !== undefined) {
            return this.data[name];
        }

        if (this.parent && ignoreParents !== true) {
            if (name == 'parent') {
                return this.parent.data;
            }

            return this.parent.get(name);
        }

        return null;
    },

    add: function(name, value) {
        if (this.data[name]) {
            if (Array.isArray(this.data[name])) {
                this.data[name].push(value);
            } else {
                this.data[name] = [this.data[name], value];
            }
        } else {
            this.set(name, value);
        }
    },

    set: function(name, value) {
        if (typeof name == 'object') {
            dmx.batch(() => {
                for (var prop in name) {
                    if (name.hasOwnProperty(prop)) {
                        this.set(prop, name[prop]);
                    }
                }
            });
            return;
        }

        if (this.data[name] !== value) {
            this.data[name] = value;
        }
    },

    del: function(name) {
        delete this.data[name];
    }
});
