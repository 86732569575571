dmx.Attribute('show', 'mounted', function(node, attr) {
  const initial = {
    value: node.style.getPropertyValue('display'),
    priority: node.style.getPropertyPriority('display'),
  };

  this.$watch(attr.value, dmx.debounce(show => {
    const value = show ? initial.value : 'none';
    const priority = show ? initial.priority : 'important';

    node.style.setProperty('display', value, priority);
  }));
});
