dmx.Component('checkbox', {

  extends: 'form-element',

  initialData: {
    checked: false,
  },

  attributes: {
    checked: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    select (check) {
      this._select(check);
    },
  },

  events: {
    checked: Event,
    unchecked: Event,
  },

  init (node) {
    dmx.Component('form-element').prototype.init.call(this, node);

    node.type = 'checkbox';
    node.checked = this.props.checked;
    node.defaultChecked = this.props.checked;

    if (this.props.checked) {
      this.set('checked', true);
    }
  },

  performUpdate (updatedProps) {
    dmx.Component('form-element').prototype.performUpdate.call(this, updatedProps);

    if (updatedProps.has('checked')) {
      this.$node.defaultChecked = this.props.checked;
      if (this.$node.checked != this.props.checked) {
        this.$node.checked = this.props.checked;
        this.set('checked', this.props.checked);
        this.dispatchEvent('updated');
        this.dispatchEvent(this.props.checked ? 'checked' : 'unchecked');
      }
    }
  },

  _select (check) {
    this.$node.checked = (check !== false);
    this.set('checked', this.$node.checked);
  },

  _changeHandler (event) {
    if (this.$node.dirty) this._validate();

    this.set('checked', this.$node.checked);
    this.dispatchEvent('updated');
    this.dispatchEvent(this.$node.checked ? 'checked' : 'unchecked');
  },

});
