dmx.Component('input-file', {

  extends: 'form-element',

  initialData: {
    file: null,
  },

  _setValue (value) {
    console.warn('Can not set value of a file input!');
  },

  _changeHandler (event) {
    dmx.Component('form-element').prototype._changeHandler.call(this, event);

    let data = null;

    if (this.$node.files.length) {
      const file = this.$node.files[0];

      data = {
        date: (file.lastModified ? new Date(file.lastModified) : file.lastModifiedDate).toISOString(),
        name: file.name,
        size: file.size,
        type: file.type,
      };

      if (file.type.includes('image/')) {
        const fileReader = new FileReader();
        fileReader.onload = event => {
          this.set('file', Object.assign({}, data, { dataUrl: event.target.result }))
        };
        fileReader.readAsDataURL(file);
      }
    }

    this.set('file', data);
  },

});
