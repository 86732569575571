dmx.Component('radio-group', {

  initialData: {
    value: null,
  },

  attributes: {
    value: {
      type: String,
      default: null,
    },
  },

  methods: {
    setValue (value) {
      this._setValue(value);
    },
  },

  events: {
    updated: Event,
  },

  init (node) {
    this._changeHandler = this._changeHandler.bind(this);

    node.addEventListener('change', this._changeHandler);

    this._setValue(this.props.value, true);
  },

  destroy () {
    this.$node.removeEventListener('change', this._changeHandler);
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('value')) {
      this._setValue(this.props.value, true);
    }
  },

  _setValue (value, defaultValue) {
    this._radios().forEach(radio => {
      radio.checked = radio.value === value;
      if (defaultValue) radio.defaultChecked = radio.checked;
    });

    this._updateValue();
  },

  _updateValue () {
    const value = this._radios().filter(radio => !radio.disabled && radio.checked).map(radio => radio.value);

    if (!dmx.equal(this.data.value, value[0])) {
      this.set('value', value[0] || null);
      this.dispatchEvent('updated');
    }
  },

  _radios () {
    return Array.from(this.$node.querySelectorAll('input[type=radio]'));
  },

  _changeHandler (event) {
    this._updateValue();
  },

});
