dmx.Component("array", {

  initialData: {
    items: [],
    count: 0,
  },

  attributes: {
    items: {
      type: Array,
      default: [],
    },
  },

  events: {
    updated: Event,
  },

  methods: {
    add (newItem) {
      this._splice(this._count(), 0, newItem);
    },

    addUniq (newItem) {
      // Only add when not exists
      if (this._indexOf(newItem) == -1) {
        this._splice(this._count(), 0, newItem);
      }
    },

    insert (index, newItem) {
      this._splice(index, 0, newItem);
    },

    insertBefore (item, newItem) {
      const index = this._indexOf(item);
      if (index != -1) {
        this._splice(index, 0, newItem);
      }
    },

    insertAfter (item, newItem) {
      const index = this._indexOf(item);
      if (index != -1) {
        this._splice(index + 1, 0, newItem);
      }
    },

    replace (item, newItem) {
      const index = this._indexOf(item);
      if (index != -1) {
        this._splice(index, 1, newItem);
      }
    },

    replaceAt (index, newItem) {
      this._splice(index, 1, newItem);
    },

    remove (item) {
      const index = this._indexOf(item);
      if (index != -1) {
        this._splice(index, 1);
      }
    },

    removeAt (index) {
      this._splice(index, 1);
    },

    reverse () {
      this._reverse();
    },

    sort () {
      this._sort();
    },

    empty () {
      this._updateData([]);
    },
  },

  render: false,

  init () {
    const arr = dmx.array(this.props.items);
    this.set({
      items: arr,
      count: arr.length,
    });
  },

  performUpdate (updated) {
    if (updated.has("items")) {
      this._updateData(dmx.array(this.props.items));
    }
  },

  _count () {
    return this.data.items.length;
  },

  _indexOf (item) {
    return this.data.items.indexOf(item);
  },

  _splice (index, remove, item) {
    const arr = dmx.clone(this.data.items);

    if (item !== undefined) {
      arr.splice(index, remove, item);
    } else {
      arr.splice(index, remove);
    }

    this._updateData(arr);
  },

  _reverse () {
    const arr = dmx.clone(this.data.items);
    arr.reverse();
    this._updateData(arr);
  },

  _sort () {
    const arr = dmx.clone(this.data.items);
    arr.sort();
    this._updateData(arr);
  },

  _updateData (arr) {
    if (!dmx.equal(this.data.items, arr)) {
      this.set({
        items: arr,
        count: arr.length,
      });

      this.dispatchEvent("updated");
    }
  },

});
