dmx.Component('select', {

  extends: 'form-element',

  initialData: {
    selectedIndex: -1,
    selectedValue: '',
    selectedText: '',
  },

  attributes: {
    options: {
      type: Array,
      default: [],
    },

    optiontext: {
      type: String,
      default: '$value',
    },

    optionvalue: {
      type: String,
      default: '$value',
    },
  },

  methods: {
    setSelectedIndex (index) {
      this.$node.selectedIndex = index;
      this._updateValue();
    },
  },

  init (node) {
    this._options = [];

    if (!this.props.value) {
      this.props.value = this.$node.value;
      this._updateValue();
    }

    dmx.Component('form-element').prototype.init.call(this, node);
  },

  render (node) {
    this.$parse();
  },

  performUpdate (updatedProps) {
    dmx.Component('form-element').prototype.performUpdate.call(this, updatedProps);

    if (updatedProps.has('options') || updatedProps.has('optiontext') || updatedProps.has('optionvalue')) {
      this._renderOptions();
    }
  },

  _setValue (value, defaultValue) {
    if (value == null) value = '';

    Array.from(this.$node.options).forEach(option => {
      option.selected = (option.value === value);
      if (defaultValue) option.defaultSelected = option.selected;
    });

    this._updateValue();
  },

  _updateValue () {
    const selectedIndex = this.$node.selectedIndex;
    const selected = this.$node.options[selectedIndex] || { value: '', text: '' };

    this.set({
      selectedIndex: selectedIndex,
      selectedValue: selected.value,
      selectedText: selected.text,
      value: selected.value,
    });
  },

  _renderOptions () {
    if (this.props.options.length) {
      this._options.splice(0).forEach(option => option.remove());

      dmx.repeatItems(this.props.options).forEach(option => {
        const node = document.createElement('option');
        node.value = dmx.parse(this.props.optionvalue, dmx.DataScope(option, this));
        node.textContent = dmx.parse(this.props.optiontext, dmx.DataScope(option, this));
        this.$node.append(node);
        this._options.push(node);
      });

      this._updateValue();
    }
  },

  _changeHandler (event) {
    if (this.$node.dirty) this._validate();

    this._updateValue();
    this.dispatchEvent('changed');
    this.dispatchEvent('updated');
  },

});
